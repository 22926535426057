import React, { ReactElement, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Container,
  makeStyles,
  Box,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { URLS } from 'src/utils/consts';
import { IReduxState } from 'src/store/reducers';
import { apiGetUser } from 'src/api/user';
import {
  Add,
  CalendarToday,
  CreditCard,
  DirectionsCarOutlined,
  Schedule,
  ThumbUpOutlined,
  LocationOnOutlined,
} from '@material-ui/icons';
import { ResponseGetUser } from 'src/types';
import { logout, setUser } from 'src/store/actions';
import mixPanel from 'src/utils/mixpanel';
import { useFontStyles } from './components/useFontStyles';
import ModalUpdateCC from './components/Modals/UpdateCC';
import VehicleForm from './components/Modals/VehicleForm';
import PastAppointments from './components/Modals/PastAppointments';
import UpcomingAppointments from './components/Modals/UpcomingAppointments';
import Vehicles from './components/Modals/Vehicles';
import ServiceModal from './components/Modals/ServiceModal';
import Feedback from './components/Modals/Feedback';
import PaymentMethods from './components/Modals/PaymentMethods';
import GpsServices from './components/Modals/GpsServices';

export enum MODALS {
  NONE,
  REFERRAL,
  MEMBER,
  UPDATE_CARD,
  PAST_APPOINTMENTS,
  VEHICLES,
  NEW_VEHICLE,
  UPCOMING_SERVICES,
  CARD_ON_FILE,
  FEEDBACK,
  Gps,
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '50px 120px',
    background: '#fff',
    height: '100%',
  },
  header: {
    marginTop: '10px',
    gridColumn: '1 / -1',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  title: {
    marginRight: '20px',
  },
  memberStatus: {
    marginBlock: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    '& > span': {
      marginRight: '10px',
    },
  },
  table: {
    minWidth: 650,
  },
  memberLoyalty: {
    listStyle: 'none',
    padding: 0,
    '& > li': {
      display: 'flex',
      alignItems: 'center',
      padding: '8px',
      fontFamily: 'Tiempos',
      fontSize: '14px',
      fontWeight: 600,
      '& svg': {
        marginRight: '5px',
      },
    },
  },
  unlockedItem: {
    borderBottom: '1px solid #24cf93',
    paddingBottom: '1px',
  },
  lockedItem: {
    filter: 'blur(5px)',
  },
  upcomingService: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  actionButtons: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  healthCard: {
    height: '100%',
  },
  yourDashboard: {
    display: 'flex',
    flexDirection: 'column',
  },
  accordionRoot: {
    boxShadow: 'none',
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
    '& .MuiPaper-root': {
      borderRadius: '10px',
    },
    '& .MuiAccordion-rounded:first-child': {
      borderRadius: '10px',
    },
    '& .MuiAccordion-rounded:last-child': {
      borderRadius: '10px',
    },
  },
  accordionSummary: {
    borderRadius: '10px 10px 10px 10px',
    background: '#EBF1FA',
    padding: '20px',
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    alignItems: 'center',
  },
  accordionDetails: {
    marginBottom: '10px',
    padding: '20px',
    display: 'block',
  },
  btnClass: {
    width: '80%',
    margin: '10px auto',
  },
  sectionTitle: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '17px',
    letterSpacing: '0.02em',
    color: '#4C4F4E',
    textTransform: 'capitalize',
  },
  sectionBox: {
    borderRadius: 16,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '2px solid #1A5B74',
    cursor: 'pointer',
    transition: '0.2s ease-in-out',
    textDecoration: 'none',
    '&:hover': {
      background: '#E9F7E7',
    },
  },
  referralInfo: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0em',
    margin: 0,
    color: '#1F2322',
  },
  referralSubtext: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '0em',
    margin: 0,
    color: '#646867',
  },
  referralButton: {
    borderRadius: '100%',
    padding: theme.spacing(1),
    minWidth: 40,
    background: '#E6EEEE',
    '& span': {
      height: 24,
      width: 24,
    },
  },
  btns: {
    '& .MuiButton-label': {
      justifyContent: 'start',
      alignItems: 'center',
    },
    color: '#646867',
  },
  buttonSelected: {
    borderRight: '3px solid #005959',
    borderRadius: 0,
    '& .MuiButton-label': {
      justifyContent: 'start',
      alignItems: 'center',
    },
  },
  scheduleButton: {
    maxWidth: 'fit-content',
    alignItems: 'center',
    display: 'flex',
    alignSelf: 'flex-end',
    marginBottom: 20,
    background: '#005959',
    color: 'white',
    marginRight: 100,
    borderRadius: 8,
    '&: hover': {
      background: '#005959',
      color: 'white',
    },
  },
}));

const promise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

const Dashboard = (): ReactElement => {
  const fontClasses = useFontStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const loggedIn = useSelector((state: IReduxState) => state.auth.loggedIn);
  if (!loggedIn) {
    history.push(URLS.LOGIN);
  }

  useEffect(() => {
    const timerId = setTimeout(async () => {
      const userResp: ResponseGetUser = await apiGetUser();

      if (userResp && userResp.data) {
        dispatch(setUser(userResp.data));
      } else {
        dispatch(logout());
      }
    });
    return () => {
      clearTimeout(timerId);
    };
  }, [dispatch]);

  const user = useSelector((state: IReduxState) => state.auth.user);

  const [currentModal, setCurrentModal] = React.useState<MODALS>(
    user?.attributes.no_obds ? MODALS.UPCOMING_SERVICES : MODALS.Gps
  );

  const showModal = (modal: MODALS) => {
    return () => {
      setCurrentModal(modal);
    };
  };

  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);

  const openServiceModal = () => setIsServiceModalOpen(true);
  const closeServiceModal = () => setIsServiceModalOpen(false);

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      if (event.state && event.state.from === 'dashboard') {
        // If the user presses the back button, push the "/dashboard" route again
        history.push(URLS.DASHBOARD);
      }
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [history]);

  useEffect(() => {
    const unblock = history.block(
      (location: { pathname: string }, action: unknown): boolean => {
        if (location.pathname !== URLS.DASHBOARD) {
          return false;
        }
        return true;
      }
    );

    return () => {
      unblock();
    };
  }, [history]);

  return (
    <Elements stripe={promise}>
      <Container className={classes.root} style={{ maxWidth: '100%' }}>
        <section
          className={classes.header}
          style={{ display: 'flex', justifyContent: 'space-between', gap: 50 }}
        >
          <h1
            className={clsx(fontClasses.h1, classes.title)}
            style={{ fontFamily: 'Inter' }}
          >
            Hi, {user?.attributes.name}
          </h1>
          <Button
            className={classes.scheduleButton}
            variant="outlined"
            color="primary"
            onClick={() => {
              openServiceModal();
              mixPanel('Fleet Schedule Service', user?.id);
            }}
          >
            <Add style={{ marginRight: 10 }} />
            <Typography style={{ fontFamily: 'Inter', textTransform: 'none' }}>
              {' '}
              Schedule Service
            </Typography>
          </Button>
        </section>

        <div style={{ display: 'flex', gap: 20 }}>
          <section className={classes.actionButtons}>
            <Box style={{ margin: '10px 0' }}>
              <Button
                className={
                  currentModal === MODALS.Gps
                    ? classes.buttonSelected
                    : classes.btns
                }
                style={{
                  fontFamily: 'Inter',
                  fontSize: 14,
                  width: 200,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
                color="primary"
                onClick={showModal(MODALS.Gps)}
              >
                <LocationOnOutlined
                  style={{ flexShrink: 0, width: '12%', height: '12%' }}
                />
                <span style={{ marginLeft: 8, textAlign: 'left' }}>GPS</span>
              </Button>
              <Button
                className={
                  currentModal === MODALS.VEHICLES
                    ? classes.buttonSelected
                    : classes.btns
                }
                style={{
                  fontFamily: 'Inter',
                  fontSize: 14,
                  width: 200,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginTop: 8,
                }}
                color="primary"
                onClick={showModal(MODALS.VEHICLES)}
              >
                <DirectionsCarOutlined
                  style={{ flexShrink: 0, width: '12%', height: '12%' }}
                />
                <span style={{ marginLeft: 8, textAlign: 'left' }}>
                  Vehicles
                </span>
              </Button>
              <Button
                className={
                  currentModal === MODALS.UPCOMING_SERVICES
                    ? classes.buttonSelected
                    : classes.btns
                }
                style={{
                  fontFamily: 'Inter',
                  fontSize: 14,
                  width: 200,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginTop: 8,
                }}
                color="primary"
                onClick={showModal(MODALS.UPCOMING_SERVICES)}
              >
                <CalendarToday
                  style={{ flexShrink: 0, width: '12%', height: '12%' }}
                />
                <span style={{ marginLeft: 8, textAlign: 'left' }}>
                  Upcoming Services
                </span>
              </Button>
            </Box>
            <Button
              className={
                currentModal === MODALS.PAST_APPOINTMENTS
                  ? classes.buttonSelected
                  : classes.btns
              }
              style={{
                fontFamily: 'Inter',
                fontSize: 14,
                width: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
              color="primary"
              onClick={showModal(MODALS.PAST_APPOINTMENTS)}
            >
              <Schedule
                style={{ flexShrink: 0, width: '12%', height: '12%' }}
              />
              <span style={{ marginLeft: 8, textAlign: 'left' }}>
                Past Services
              </span>
            </Button>

            <Button
              className={
                currentModal === MODALS.CARD_ON_FILE
                  ? classes.buttonSelected
                  : classes.btns
              }
              style={{
                fontFamily: 'Inter',
                fontSize: 14,
                width: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
              color="primary"
              onClick={showModal(MODALS.CARD_ON_FILE)}
            >
              <CreditCard
                style={{ flexShrink: 0, width: '10%', height: '10%' }}
              />
              <span style={{ marginLeft: 8, textAlign: 'left' }}>
                Payment Info
              </span>
            </Button>

            <Button
              className={
                currentModal === MODALS.FEEDBACK
                  ? classes.buttonSelected
                  : classes.btns
              }
              style={{
                fontFamily: 'Inter',
                fontSize: 14,
                width: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
              color="primary"
              onClick={showModal(MODALS.FEEDBACK)}
            >
              <ThumbUpOutlined
                style={{ flexShrink: 0, width: '10%', height: '10%' }}
              />
              <span style={{ marginLeft: 8, textAlign: 'left' }}>Feedback</span>
            </Button>
          </section>

          {currentModal === MODALS.UPCOMING_SERVICES && (
            <UpcomingAppointments />
          )}
          {currentModal === MODALS.PAST_APPOINTMENTS && <PastAppointments />}
          {currentModal === MODALS.VEHICLES && <Vehicles />}
          {currentModal === MODALS.CARD_ON_FILE && <PaymentMethods />}
          {currentModal === MODALS.FEEDBACK && <Feedback userId={user?.id} />}

          {currentModal === MODALS.Gps && <GpsServices />}
        </div>
      </Container>
      <ModalUpdateCC
        show={currentModal === MODALS.UPDATE_CARD}
        onClose={showModal(MODALS.NONE)}
      />

      <VehicleForm
        open={currentModal === MODALS.NEW_VEHICLE}
        onClose={showModal(MODALS.NONE)}
        type="create"
        carId={undefined}
        carDetails={undefined}
      />
      <ServiceModal isOpen={isServiceModalOpen} onClose={closeServiceModal} />
    </Elements>
  );
};

export default Dashboard;
